import React, { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextInputArea } from '../../atoms/react-hook-form-input-elements/text-input-area/TextInputArea';
import { AugurReport, Config } from './type';
import { findElementMeta } from '../../molecules/augur-layout-elements/common/utils';
import { AugurReportElementMeta } from '../../molecules/augur-layout-elements/report-elements/types/meta';
import { ConfigForm, isValidJson } from './utils/config.form';
import SplitViewElementEditor from './SplitViewElementEditor';
import IntlTextInputLine from '../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import styles from './styles.module.scss';
import Button from '../../atoms/button/Button';
import { LuFileJson } from 'react-icons/lu';
import Modal from '../../organisms/modal/Modal';
import Ajv from 'ajv';

export type EditReportSectionProps = {
  selectedElementId: string;
  sampleReport: AugurReport;
  onBack: () => void;
};

const EditReportSectionPage: FC<EditReportSectionProps> = ({
  selectedElementId,
  sampleReport,
  onBack,
}) => {
  const { control, watch, getValues } = useFormContext<ConfigForm>();

  const element = getValues(`elements.${selectedElementId}`);
  const elementMeta = findElementMeta(
    element.type,
    element.version
  ) as AugurReportElementMeta;

  const defaultReportData = elementMeta.reportDataDefault;

  const reportKey = watch(`elements.${selectedElementId}.reportKey`);
  const reportData = sampleReport?.reportData[reportKey] || defaultReportData;

  const isShowingDefaultData = !sampleReport?.reportData[reportKey];

  const [editableReportDataString, setEditableReportDataString] =
    useState<string>(JSON.stringify(reportData, null, 2));
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setEditableReportDataString(JSON.stringify(reportData, null, 2));
  }, [reportData]);

  function validateReportType(value: string): string {
    const ajv = new Ajv({ allErrors: true });
    const isValidJsonResult = isValidJson(value);
    if (isValidJsonResult !== true) {
      return isValidJsonResult.toString();
    }

    const parsedValue: Config<Record<string, unknown>> = JSON.parse(value);
    const validation = elementMeta.validateReportDataType;

    if (!validation(parsedValue)) {
      return ajv.errorsText(validation.errors);
    }
    return '';
  }

  const reportTypeError = validateReportType(editableReportDataString);

  const rightPanelContent = (
    <>
      {showModal && (
        <Modal
          show={showModal}
          hideModal={() => setShowModal(false)}
          headline={{
            id: 'no-id',
            defaultMessage: 'Current Augur Report file',
          }}
        >
          <div className={styles.modalTextArea}>
            <TextInputArea
              amountRows={24}
              label={'Augur Report'}
              disabled={true}
              value={
                JSON.stringify(sampleReport.reportData, null, 2) ??
                'No report available.'
              }
            />
          </div>
        </Modal>
      )}

      <div>
        <Controller
          name={`elements.${selectedElementId}.reportKey`}
          control={control}
          render={({ field, fieldState }) => {
            const { ref, ...rest } = field; // extract ref to pass as inputRef
            return (
              <IntlTextInputLine
                label={'Report Key'}
                placeholder={'Type an identifier here'}
                {...rest}
                {...fieldState}
                isTouched={true}
                inputRef={ref}
                error={fieldState.error?.message}
              />
            );
          }}
        />
        <div
          className={styles.showFileContainer}
          onClick={() => setShowModal(true)}
        >
          <LuFileJson className={styles.showFileIcon} size={13} />
          <span className={styles.showFileText}>
            Show current Augur Report file
          </span>
        </div>
      </div>

      <div>
        <div className={styles.alignEnd}>
          <Button
            color='white'
            label='Restore report data from key'
            onClick={() => {
              setEditableReportDataString(JSON.stringify(reportData, null, 2));
            }}
          />
        </div>
        <TextInputArea
          label='Report Data'
          placeholder='Enter report data'
          value={editableReportDataString}
          onChange={(e) => {
            setEditableReportDataString(e.target.value);
          }}
          amountRows={10}
          error={reportTypeError}
          isTouched
        />

        <span className={styles.lightTextItalic}>
          Note: Changes made to the report data are for experimental purposes
          and will not be saved.
        </span>
      </div>

      <TextInputArea
        label='Report Data Type'
        disabled={true}
        value={elementMeta.reportDataType}
        amountRows={5}
      />
    </>
  );

  const parsedReport =
    isValidJson(editableReportDataString) === true
      ? JSON.parse(editableReportDataString)
      : undefined;

  const modifiedSampleReport = {
    ...sampleReport,
    reportData: {
      ...sampleReport.reportData,
      [reportKey]: parsedReport, // Preview of editable report data
    },
  };

  return (
    <SplitViewElementEditor
      selectedElementId={selectedElementId}
      sampleReport={modifiedSampleReport}
      isElementInvalid={reportTypeError !== ''}
      rightPanelContent={rightPanelContent}
      onBack={onBack}
      fullscreenDisabled={true}
      title='Edit Report Data'
      leftPanelText={
        isShowingDefaultData
          ? `No report data for specified report key. Displaying default report data.`
          : `Displaying report data for report key "${reportKey}".`
      }
    />
  );
};

export default EditReportSectionPage;
